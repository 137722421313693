<template>

<app-content :loading="is.loading" :fullheight="true">

	<app-content-head title="Badges" :is-app="true" />

	<app-content-body class="badge">

		<div class="badge-summary" v-if="item">

			<app-panel-content>

				<div class="badge-summary-name">{{ item.name }}</div>
				<div class="badge-summary-reference">Ref. {{ item.referenceRandom }}</div>
				<div class="badge-summary-email">{{ item.email }}</div>

			</app-panel-content>

			<div class="badge-summary-type" :style="{backgroundColor: item.type.colour}">{{ item.type.label }}</div>

		</div>

		<div class="badge-content" v-if="item">
		
			<a ref="image" :href="preview" download><img :src="preview" class="badge-preview" /></a>

		</div>

		<div class="badge-actions" v-if="item">

			<app-button :disabled="is.printing" text="Download" theme="blue" v-on:click="onDownloadClick" />
			<app-button :disabled="is.saving" text="Close" theme="grey" v-on:click="onCloseClick" />

		</div>

	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	data: function() {

		return {
			is: {
				loading: true,
				saving: false
			},
			item: false,
			preview: false
		}

	},

	created: function() {

		this.$api.get(['badges/badge', this.$route.params.id]).then(function(json) {

			Vue.set(this, 'item', json.badge)
			this.preview = json.preview
			
			this.is.loading = false

		}.bind(this))

	},
	
	methods: {

		onDownloadClick: function() {
			
			this.$refs.image.click()
			
		},

		onCloseClick: function() {

			this.$router.push({
				name: 'Badges.Dashboard'
			})

		}

	}

}

</script>

<style scoped>

.badge {
	background-color: #fff;
	display: flex;
	padding: 0px!important;
	flex-direction: column;
}

.badge-summary {
	padding-top: 20px;
	background-color: #384352;
	flex-shrink: 0;
}

.badge-summary-name {
	text-align: center;
	font-weight: 400;
	color: #fff;
	font-size: 24px;
}

.badge-summary-reference {
	text-align: center;
	font-weight: 300;
	color: rgba(255, 255, 255, 0.75);
	font-size: 14px;
	margin-top: 4px;
}

.badge-summary-email {
	text-align: center;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.75);
	font-size: 14px;
	margin-top: 20px;
}

.badge-summary-type {
	text-align: center;
	padding: 10px 20px;
	font-weight: 400;
	color: #fff;
}

.badge-content {
	flex-grow: 1;
	display: flex;
	align-items: center;
	background-color: #eee;
	justify-content: center;
}

.badge-preview {
	max-width: 100%;
}

.badge-actions {
	flex-shrink: 0;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	background-color: #384352;
}

.badge-actions >>> .button {
	width: calc(50% - 10px);
}

</style>
